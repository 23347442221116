import React from 'react'
import './index.scss'
import { motion, AnimatePresence } from 'framer-motion'

const modalBackgroundVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
}

const modalContentVariants = {
  hidden: { scale: 0.95 },
  visible: { scale: 1 },
  exit: { scale: 0.98 },
}

const Modal = ({ isVisible, onClose, portfolioItem }) => {
  return (
    <AnimatePresence mode="wait">
      {isVisible && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalBackgroundVariants}
          transition={{ damping: 10, stiffness: 300 }}
          className="modal"
          onClick={onClose}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={modalContentVariants}
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="close-button" onClick={onClose}>
              x
            </button>
            <div className="main-content">
              <img src={portfolioItem.image} alt="portfolio" />
              <span>
                <h2>{portfolioItem.name}</h2>
              </span>
              <hr></hr>
              <span className="sections">
                <p>Company:</p>
                {/* <h3>{portfolioItem.company}</h3> */}
                <h3>*Classified*</h3>
              </span>
              <span className="sections">
                <p>Description:</p>
                <p className="description">{portfolioItem.full_description}</p>
              </span>
              {portfolioItem.impact && (
                <span className="sections">
                  <p>Impact:</p>
                  <p className="description">{portfolioItem.impact}</p>
                </span>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Modal
