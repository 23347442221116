import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getDocs, collection } from 'firebase/firestore'
import { db } from '../../firebase'
import './index.scss'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import Login from './login'
import AddReview from './Modal'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

const References = () => {
  const navigate = useNavigate()
  const [letterClass, setLetterClass] = useState('text-animate')
  const [user, setUser] = useState(null)
  const auth = getAuth()

  const [reviews, setReviews] = useState([])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  })

  useEffect(() => {
    getReviews()

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })
  }, [])

  const getReviews = async () => {
    console.log(db)
    const querySnapshot = await getDocs(collection(db, 'reviews'))
    console.log(querySnapshot)
    setReviews(querySnapshot.docs.map((doc) => doc.data()))
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const renderReviews = (reviews) => {
    return (
      <Carousel
        additionalTransfrom={0}
        autoPlaySpeed={3000}
        responsive={responsive}
        // centerMode={true}
        draggable={false}
        showDots={false}
        arrows={true}
        infinite={true}
        className="reviews-container"
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        swipeable
      >
        {reviews.map((review, idx) => {
          const occupation = review.occupation + ' at ' + review.company
          return (
            <motion.div
              id={idx}
              key={idx}
              initial={{
                opacity: 0,
                x: -100,
              }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                delay: 1.2 * idx + 1,
                duration: 1.8,
                ease: [0, 0.71, 0.2, 1.01],
                type: 'keyframes',
              }}
              className="review-box"
            >
              <img src={review.image} className="review-image" alt="review" />
              <p className="title">{review.name}</p>
              <p className="occupation">{occupation}</p>
              <p className="comment">{review.comment}</p>
            </motion.div>
          )
        })}
      </Carousel>
    )
  }

  return (
    <>
      <div className="container references-page">
        <h1 className="page-title">
          <AnimatedLetters
            letterClass={letterClass}
            strArray={'Endorsements'.split('')}
            idx={15}
          />
        </h1>
        <div>{renderReviews(reviews)}</div>
        <div className="login">{user ? <AddReview /> : <Login />}</div>
        <div
          className="page-nav previous-page arrowUp"
          onClick={() => navigate('/portfolio')}
        >
          <KeyboardDoubleArrowUpIcon sx={{ fontSize: '85px' }} />
        </div>
        <div
          className="page-nav next-page arrowDown"
          onClick={() => navigate('/contact')}
        >
          <KeyboardDoubleArrowDownIcon sx={{ fontSize: '85px' }} />
        </div>
      </div>
      <Loader type="line-scale" />
    </>
  )
}

export default References
