import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import FadingText from './FadingText'
import Timeline from './Timeline'
import './index.scss'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

const Experience = () => {
  const navigate = useNavigate()
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container experience-page">
        <h1 className="page-title">
          <AnimatedLetters
            letterClass={letterClass}
            strArray={'My Experience'.split('')}
            idx={15}
          />
        </h1>
        <div className="fading-text">
          <FadingText
            text="From counting pennies to analyzing stocks, I've done it all in my illustrious finance career. During this period, I earned my CFA designation the hard way: by drinking copious amounts of coffee. I'm your go-to person for all things finance. Just don't ask me to calculate the tip at dinner - I'm terrible at math under pressure."
            initialDelay={2000}
          />
        </div>
        <div className="timeline">
          <Timeline />
        </div>
        <div
          className="page-nav previous-page arrowUp"
          onClick={() => navigate('/about')}
        >
          <KeyboardDoubleArrowUpIcon sx={{ fontSize: '85px' }} />
        </div>
        <div
          className="page-nav next-page arrowDown"
          onClick={() => navigate('/portfolio')}
        >
          <KeyboardDoubleArrowDownIcon sx={{ fontSize: '85px' }} />
        </div>
      </div>
      <Loader type="line-scale" />
    </>
  )
}

export default Experience
