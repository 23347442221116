import { useRef } from 'react'
import { auth, storage, db } from '../../../../firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { addDoc } from 'firebase/firestore'
// import { collection } from 'firebase/firestore/lite'
import { collection } from 'firebase/firestore'
import './index.scss'

const Home = () => {
  const form = useRef()

  const submitReview = (e) => {
    e.preventDefault()
    const name = form.current[0]?.value
    const occupation = form.current[1]?.value
    const company = form.current[2]?.value
    const comment = form.current[3]?.value
    const image = form.current[4]?.files[0]

    const storageRef = ref(storage, `review/${image.name}`)

    uploadBytes(storageRef, image).then(
      (snapshot) => {
        getDownloadURL(snapshot.ref).then(
          (downloadUrl) => {
            saveReview({
              name,
              occupation,
              company,
              comment,
              image: downloadUrl,
            })
          },
          (error) => {
            console.log(error)
            saveReview({
              name,
              occupation,
              company,
              comment,
              image: null,
            })
          }
        )
      },
      (error) => {
        console.log(error)
        saveReview({
          name,
          occupation,
          company,
          comment,
          image: null,
        })
      }
    )
  }

  const saveReview = async (review) => {
    try {
      console.log(review) // log the review object
      // Check if the image field is not null
      if (review.image) {
        await addDoc(collection(db, 'reviews'), review)
        window.location.reload(false)
      } else {
        alert('Failed to add review: image is null')
      }
    } catch (error) {
      alert('Failed to add review: ' + error.message)
    }
  }

  return (
    <div className="input">
      <form ref={form} onSubmit={submitReview}>
        <p>
          <input type="text" placeholder="Name" required maxLength={20} />
        </p>
        <p>
          <input type="text" placeholder="Your job title" required maxLength={20}/>
        </p>
        <p>
          <input type="text" placeholder="Your current company" required maxLength={15} />
        </p>
        <p>
          <textarea
            placeholder="Your recommendation"
            cols={50}
            rows={10}
            minLength="300"
            maxLength="380"
            required
          />
        </p>
        <p>
          <input
            type="file"
            placeholder="Image"
            onChange={(e) => (form.current[4].value = e.target.value)}
            required
          />
        </p>
        <button type="submit">Submit</button>
      </form>
      <button className='signout' onClick={() => auth.signOut()}>Sign Out / Close</button>
    </div>
  )
}

export default Home
