import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo-d.png'
import Logo from './Logo'
import './index.scss'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'

const Home = () => {
  const navigate = useNavigate()
  const [letterClass, setLetterClass] = useState('text-animate')
  const [imageAnimationClass, setImageAnimationClass] =
    useState('initial-animation')

  const nameArray = ['a', 's', 't', 'a', 'n']
  const jobArray = [
    'f',
    'i',
    'n',
    'a',
    'n',
    'c',
    'e',
    ' ',
    'e',
    'x',
    'p',
    'e',
    'r',
    't',
    '.',
  ]

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageAnimationClass('loaded')
    }, 2400) // 1400ms delay + 1000ms animation duration

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i</span>
            <span className={`${letterClass} _12`}>,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'</span>
            <span className={`${letterClass} _14`}>m</span>
            <img
              className={imageAnimationClass}
              src={LogoTitle}
              alt="JavaScript Developer Name, Web Developer Name"
            />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <span className={`${letterClass} _12`}>,</span>
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <h2>Financial Analysis / FP&A / Systems</h2>
          <Link to="/contact" className="flat-button">
            CONTACT ME
          </Link>
        </div>
        <Logo />
        <div className="page-nav next-page" onClick={() => navigate('/about')}>
          <KeyboardDoubleArrowDownIcon sx={{ fontSize: '85px' }} />
        </div>
      </div>

      <Loader type="line-scale" />
    </>
  )
}

export default Home
