import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import Modal from './Modal'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { getDocs, collection } from 'firebase/firestore'
import { db } from '../../firebase'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

const Portfolio = () => {
  const navigate = useNavigate()
  const [letterClass, setLetterClass] = useState('text-animate')
  const [portfolio, setPortfolio] = useState([])
  const [initialRender, setInitialRender] = useState(true)

  const [isModalVisible, setModalVisible] = useState(false)
  const [selectedPortfolio, setSelectedPortfolio] = useState({})

  const [activeFilter, setActiveFilter] = useState('All Projects')

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  })

  useEffect(() => {
    getPortfolio()
    setTimeout(() => {
      setInitialRender(false)
    }, 2000) // 2 seconds delay
  }, [])

  const getPortfolio = async () => {
    console.log(db)
    const querySnapshot = await getDocs(collection(db, 'portfolio'))
    console.log(querySnapshot)
    setPortfolio(querySnapshot.docs.map((doc) => doc.data()))
  }

  const renderPortfolio = (portfolio) => {
    const filteredPortfolio =
      activeFilter === 'All Projects'
        ? portfolio
        : portfolio.filter((port) => port.category === activeFilter)

    return (
      <motion.div layout className="images-container">
        <AnimatePresence>
          {filteredPortfolio.map((port, idx) => {
            const animationProps = initialRender
              ? {
                  initial: { opacity: 0, y: 200 },
                  animate: { opacity: 1, y: 0 },
                  transition: {
                    delay: 2 + 0.3 * idx,
                    duration: 1,
                    ease: 'easeOut',
                    type: 'keyframes',
                  },
                }
              : {
                  animate: { opacity: 1, scale: 1 },
                  initial: { opacity: 0, scale: 0 },
                  exit: { opacity: 0, scale: 0 },
                  transition: { duration: 0.3, ease: 'easeOut' },
                }
            return (
              <motion.div
                layout
                key={port.id}
                {...animationProps}
                className="image-box"
              >
                <img
                  src={port.image}
                  className="portfolio-image"
                  alt="portfolio"
                />
                <div className="content">
                  <p className="title">{port.name}</p>
                  <h4 className="description">{port.description}</h4>
                  <button
                    className="btn"
                    onClick={() => {
                      setSelectedPortfolio(port)
                      setModalVisible(true)
                    }}
                  >
                    View
                  </button>
                </div>
              </motion.div>
            )
          })}
        </AnimatePresence>
      </motion.div>
    )
  }

  return (
    <>
      <div className="container portfolio-page">
        <h1 className="page-title">
          <AnimatedLetters
            letterClass={letterClass}
            strArray={'Portfolio'.split('')}
            idx={15}
          />
        </h1>
        <div className="main-content">
          <div className="filters-container">
            <p
              className={`all-projects filter ${
                activeFilter === 'All Projects' ? 'active-category' : ''
              }`}
              onClick={() => setActiveFilter('All Projects')}
            >
              &lt;All Projects&gt;
            </p>
            <p
              className={`child-projects filter ${
                activeFilter === 'forecasting' ? 'active-category' : ''
              }`}
              onClick={() => setActiveFilter('forecasting')}
            >
              &lt;Forecasting /&gt;
            </p>
            <p
              className={`child-projects filter ${
                activeFilter === 'process_improvement' ? 'active-category' : ''
              }`}
              onClick={() => setActiveFilter('process_improvement')}
            >
              &lt;Process Improvement /&gt;
            </p>
            <p
              className={`child-projects filter ${
                activeFilter === 'system_implementation'
                  ? 'active-category'
                  : ''
              }`}
              onClick={() => setActiveFilter('system_implementation')}
            >
              &lt;System Implementation /&gt;
            </p>
            <p
              className={`child-projects filter ${
                activeFilter === 'analysis' ? 'active-category' : ''
              }`}
              onClick={() => setActiveFilter('analysis')}
            >
              &lt;Analysis /&gt;
            </p>
            <p
              className={`child-projects filter ${
                activeFilter === 'other' ? 'active-category' : ''
              }`}
              onClick={() => setActiveFilter('other')}
            >
              &lt;Other /&gt;
            </p>
            <p
              className={`all-projects filter ${
                activeFilter === 'All Projects' ? 'active-category' : ''
              }`}
              onClick={() => setActiveFilter('All Projects')}
            >
              &lt;/All Projects&gt;
            </p>
          </div>
          <div>{renderPortfolio(portfolio)}</div>
        </div>
        <div
          className="page-nav previous-page arrowUp"
          onClick={() => navigate('/experience')}
        >
          <KeyboardDoubleArrowUpIcon sx={{ fontSize: '85px' }} />
        </div>
        <div
          className="page-nav next-page arrowDown"
          onClick={() => navigate('/references')}
        >
          <KeyboardDoubleArrowDownIcon sx={{ fontSize: '85px' }} />
        </div>
      </div>
      <Modal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        portfolioItem={selectedPortfolio}
      />
      <Loader type="line-scale" />
    </>
  )
}

export default Portfolio
