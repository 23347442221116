export const timelineElements = [
  {
    id: 1,
    title: 'American University of Central Asia',
    description:
      'I always had big dreams of becoming the next Warren Buffet - minus the billions, of course. So, I decided to pursue a career in finance and landed a spot at the most prestigious university in my country. After pulling more all-nighters than I care to count and surviving on a diet of ramen noodles, I finally earned my degree in Business Administration.',
    date: '2012 - 2016',
    category: 'Education',
    icon: 'school',
    img: 'auca',
    url: 'https://www.auca.kg/',
  },
  {
    id: 2,
    title: 'OXUS Microfinance',
    description:
      "By shaping the financial future of OXUS Microfinance, my journey has been nothing short of remarkable. There, I didn't just create monthly reports. Working closely with the Financial Director, I immersed myself in the world of master budgeting, forecasting, and ensuring that our capital was always where it needed to be.",
    date: '2016 - 2017',
    category: 'Work',
    icon: 'work',
    img: 'oxus',
    url: 'https://www.oxusnetwork.org/oxus-kyrgyzstan',
  },
  {
    id: 3,
    title: 'Pathway Capital Management',
    description:
      'At Pathway Capital, I mastered the art of identifying standout investment opportunities. Diving deep into portfolio financials, I kept our GP reports sharp and current. My trusty web research became instrumental for thorough due diligence, and alongside, I played a pivotal role in refining our private equity strategies, ensuring precision in our portfolio listings and analysis.',
    date: '2018 - 2020',
    category: 'Work',
    icon: 'work',
    img: 'pathway',
    url: 'https://pathwaycapital.com/',
  },
  {
    id: 4,
    title: 'CODAN US Inc.',
    description:
      'After pivoting from investment management to FP&A, my role at CODAN US became a testament to this passion. I enhanced our financial performance reports, innovated our pricing model to bolster net sales, and redefined daily order processes to better track customer requests.',
    date: '2020 - 2022',
    category: 'Work',
    icon: 'work',
    img: 'codan',
    url: 'https://www.codanusa.com/',
  },
  {
    id: 5,
    title: 'Cobalt.io',
    description:
      "So, here's your nice guy who joined a startup that breaks into things for a living (legally, of course). But he's not into breaking things, he's into making things easier for his team, like automating boring financial reports. Your guy is the kind of guy who enjoys making others happy, and probably gets a lot of free coffee in return.",
    date: '2022 - Present',
    category: 'Work',
    icon: 'school',
    img: 'cobalt',
    url: 'https://www.cobalt.io/',
  },
]
