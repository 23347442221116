import { signInWithGoogle } from '../../firebase'

const Login = () => {
  return (
    <div className="login">
      <span style={{ color: 'white', fontSize: '15px' }}>
        Please
        <button
          style={{
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            color: '#ffd700',
            fontSize: '18px',
            textDecoration: 'underline',
          }}
          onClick={signInWithGoogle}
        >
          Sign In
        </button>
        to leave endoresement for Dastan.
      </span>
    </div>
  )
}

export default Login
