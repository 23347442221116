import React, { useState, useEffect } from 'react'
import './index.scss'

function FadingText({ text, initialDelay }) {
  const [wordsToShow, setWordsToShow] = useState(0)
  const [startAnimation, setStartAnimation] = useState(false)
  const words = text.split(' ')

  useEffect(() => {
    const initialTimer = setTimeout(() => {
      setStartAnimation(true)
    }, initialDelay)

    return () => clearTimeout(initialTimer)
  }, [initialDelay])

  useEffect(() => {
    if (startAnimation && wordsToShow < words.length) {
      const timer = setTimeout(() => {
        setWordsToShow((prev) => prev + 1)
      }, 100) // 100ms delay for each word

      return () => clearTimeout(timer)
    }
  }, [startAnimation, wordsToShow, words.length])

  return (
    <div className="text">
      {words.slice(0, wordsToShow).map((word, idx) => (
        <span
          key={idx}
          style={{
            animation: 'fadein 0.5s',
            display: 'inline-block',
            marginRight: '5px',
          }}
        >
          {word}
        </span>
      ))}
    </div>
  )
}

export default FadingText
