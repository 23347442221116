import * as React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import SchoolIcon from '@mui/icons-material/School'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import VaccinesIcon from '@mui/icons-material/Vaccines'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import { timelineElements } from '../data'
import './index.scss'
import auca from '../../../assets/images/AUCA.jpg'
import oxus from '../../../assets/images/oxus.png'
import cobalt from '../../../assets/images/Cobalt.jpg'
import pathway from '../../../assets/images/pathway.png'
import codan from '../../../assets/images/codan.png'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      transform: 'scaleX(1)',
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      transform: 'scaleX(1)',
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    width: 'auto',
    transform: 'scaleX(0)',
    transformOrigin: 'left center',
    transition: 'transform 0.5s ease-out',
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <SchoolIcon sx={{ fontSize: '25px' }} />,
    2: <AttachMoneyIcon sx={{ fontSize: '25px' }} />,
    3: <ShowChartIcon sx={{ fontSize: '25px' }} />,
    4: <VaccinesIcon sx={{ fontSize: '25px' }} />,
    5: <VerifiedUserIcon sx={{ fontSize: '25px' }} />,
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

const nextArrowSX = {
  marginLeft: '30%',
  color: '#ffd700',
  fontSize: '75px',
  '&:hover': {
    cursor: 'pointer',
    color: '#ffae00',
  },
}

export default function Timeline() {
  const [currentStep, setCurrentStep] = useState(0)
  const [containerWidth, setContainerWidth] = useState('220px')

  useEffect(() => {
    // Here, you can update the width based on currentStep
    // Example: Increment width by 10% for each new step, adjust as needed
    if (currentStep === 4) {
      setContainerWidth('80%')
    } else {
      setContainerWidth(`${220 + 220 * currentStep}px`)
    }
  }, [currentStep])

  const imageSelect = (image) => {
    switch (image) {
      case 'auca':
        return auca
      case 'cobalt':
        return cobalt
      case 'oxus':
        return oxus
      case 'pathway':
        return pathway
      case 'codan':
        return codan
      default:
        return auca
    }
  }

  const handleStepChange = () => {
    setCurrentStep(currentStep + 1)
  }

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '100%',
      }}
      spacing={4}
    >
      <motion.div
        initial={{ width: '220px' }}
        animate={{ width: containerWidth }}
        transition={{ duration: 1 }}
      >
        <Stepper
          alternativeLabel
          activeStep={currentStep}
          connector={<ColorlibConnector />}
          sx={{
            textAlign: 'left',
          }}
        >
          {timelineElements.map((element, idx) => {
            if (currentStep >= idx) {
              return (
                <Step
                  key={element.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    animation: 'fadeIn 2.0s',
                  }}
                >
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    sx={{
                      width: '220px',
                      position: 'relative',
                      animation: 'fadeIn 1.0s',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => window.open(element.url, '_blank')}
                  >
                    <motion.img
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        delay: 0.5,
                        duration: 1.2,
                        ease: [0, 0.71, 0.2, 1.01],
                        type: 'keyframes',
                      }}
                      className={
                        idx % 2 === 0 ? 'image image-even' : 'image image-odd'
                      }
                      src={imageSelect(element.img)}
                      alt={element.img}
                    />
                    <p
                      className={
                        idx % 2 === 0 ? 'date date-even' : 'date date-odd'
                      }
                    >
                      {element.date}
                    </p>
                    <h3
                      className={
                        idx % 2 === 0 ? 'title title-even' : 'title title-odd'
                      }
                    >
                      {element.title}
                    </h3>
                    <p
                      className={
                        idx % 2 === 0
                          ? 'description description-even'
                          : 'description description-odd'
                      }
                    >
                      {element.description}
                    </p>
                  </StepLabel>
                </Step>
              )
            }
          })}
        </Stepper>
      </motion.div>
      {currentStep < 4 && (
        <motion.div
          animate={{ x: 8 }}
          transition={{
            duration: 0.8,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        >
          <KeyboardDoubleArrowRightIcon
            sx={nextArrowSX}
            onClick={handleStepChange}
          />
        </motion.div>
      )}
    </Stack>
  )
}
