import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { faSalesforce } from '@fortawesome/free-brands-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import netSuite from '../../assets/images/NetSuite.png'
import sql from '../../assets/images/sql.png'
import python from '../../assets/images/python.png'
import bigquery from '../../assets/images/bigquery.png'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'

const About = () => {
  const navigate = useNavigate()
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  // Dragging the Cube and moving it around

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p style={{ fontSize: '17px' }}>
            Meet your finance enthusiast based in LA!
          </p>
          <p style={{ fontSize: '17px' }}>
            With a degree in finance and 5 years of hands-on experience in the
            industry, I take great pleasure in conducting financial analysis,
            forecasting, modeling, and assisting companies in enhancing their
            financial and operational performances.
          </p>
          <p align="LEFT" style={{ fontSize: '17px' }}>
            Currently, I work as an FP&A Specialist at a cybersecurity company
            in the US. I hold a Master's degree in Business Statistics from
            Westcliff University. My detail-oriented nature fuels my enthusiasm
            for analysis. Previously, I held positions as a financial analyst at
            a medical devices company, an investment analyst at a private
            equity-oriented fund, and a corporate finance analyst at a
            microfinance company.
          </p>
          <p style={{ fontSize: '17px' }}>
            When I'm not crunching numbers, you can find me exploring new
            cuisines, conquering a new hiking peak, playing video games, or
            socializing with friends. With my diverse range of interests and
            skills, this financial expert is definitely one to keep on your
            radar!
          </p>
        </div>

        <motion.div
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}
          className="stage-cube-cont"
        >
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faFileExcel} color="#1D6F42" />
            </div>
            <div className="face2">
              <div className="python-container">
                <img className="python" src={python} alt="Python Logo" />
              </div>
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faSalesforce} color="#1798C1" />
            </div>
            <div className="face4">
              <img src={netSuite} alt="NetSuite Logo" />
            </div>
            <div className="face5">
              <img className="bq" src={bigquery} alt="BigQuery Logo" />
            </div>
            <div className="face6">
              <img className="sql" src={sql} alt="SQL Logo" />
            </div>
          </div>
        </motion.div>
        <div className="page-nav previous-page" onClick={() => navigate('/')}>
          <KeyboardDoubleArrowUpIcon sx={{ fontSize: '85px' }} />
        </div>
        <div
          className="page-nav next-page"
          onClick={() => navigate('/experience')}
        >
          <KeyboardDoubleArrowDownIcon sx={{ fontSize: '85px' }} />
        </div>
      </div>
      <Loader type="line-scale" />
    </>
  )
}

export default About
