import React from 'react'
import formulaIcon from '../../assets/images/excelFormulaIcon.png'
import { TypeAnimation } from 'react-type-animation'
import './index.scss'

const Splash = () => {
  return (
    <div className="splash-container">
      <img src={formulaIcon} alt="Formula Icon" className="fx-icon" />
      <TypeAnimation
        sequence={[
          '=vlookup(',
          1000,
          "=vlookup('Dastan Salibekov', 'Best FP&As'!A:A, 1, FALSE)",
        ]}
        startDelay={500}
        wrapper="span"
        speed={32}
        style={{ fontSize: '2em', display: 'inline-block' }}
      />
    </div>
  )
}

export default Splash
