import { useEffect, useRef } from 'react'
import gsap from 'gsap-trial'
import LogoD from '../../../assets/images/photo.jpg'
import photo2 from '../../../assets/images/photo2.jpg'
import photo3 from '../../../assets/images/photo3.jpg'
import Tape from '../../../assets/images/tape-png.png'
import './index.scss'

const Logo = () => {
  const bgRef = useRef()
  const outlineLogoRef = useRef()
  const solidLogoRef1 = useRef()
  const solidLogoRef2 = useRef()
  const solidLogoRef3 = useRef()
  const solidTapeUpRef1 = useRef()
  const solidTapeDownRef1 = useRef()
  const solidTapeUpRef2 = useRef()
  const solidTapeDownRef2 = useRef()
  const solidTapeUpRef3 = useRef()
  const solidTapeDownRef3 = useRef()

  let delay1 = 1
  let delay2 = 2.5
  let delay3 = 3.5

  useEffect(() => {
    gsap
      .timeline()
      .to(bgRef.current, {
        duration: 1,
        opacity: 1,
      })
      .from(outlineLogoRef.current, {
        duration: 20,
      })

    gsap.fromTo(
      solidLogoRef1.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: delay1,
        duration: 3,
      }
    )

    gsap.fromTo(
      solidLogoRef2.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: delay2,
        duration: 3,
      }
    )

    gsap.fromTo(
      solidLogoRef3.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: delay3,
        duration: 3,
      }
    )

    gsap.fromTo(
      solidTapeUpRef1.current,
      {
        opacity: 0,
        x: -50,
      },
      {
        opacity: 1,
        x: 0,
        delay: delay1 + 1,
        duration: 1,
      }
    )

    gsap.fromTo(
      solidTapeDownRef1.current,
      {
        opacity: 0,
        x: 50,
      },
      {
        opacity: 1,
        x: 0,
        delay: delay1 + 2,
        duration: 1,
      }
    )

    gsap.fromTo(
      solidTapeUpRef2.current,
      {
        opacity: 0,
        x: -50,
      },
      {
        opacity: 1,
        x: 0,
        delay: delay2 + 1,
        duration: 1,
      }
    )

    gsap.fromTo(
      solidTapeDownRef2.current,
      {
        opacity: 0,
        x: 50,
      },
      {
        opacity: 1,
        x: 0,
        delay: delay2 + 2,
        duration: 1,
      }
    )

    gsap.fromTo(
      solidTapeUpRef3.current,
      {
        opacity: 0,
        x: -50,
      },
      {
        opacity: 1,
        x: 0,
        delay: delay3 + 1,
        duration: 1,
      }
    )

    gsap.fromTo(
      solidTapeDownRef3.current,
      {
        opacity: 0,
        x: 50,
      },
      {
        opacity: 1,
        x: 0,
        delay: delay3 + 2,
        duration: 1,
      }
    )
  }, [])

  return (
    <div className="logo-container" ref={bgRef}>
      <img className="tape-up1" ref={solidTapeUpRef1} src={Tape} alt="tape" />
      <img className="photo1" ref={solidLogoRef1} src={LogoD} alt="photo1" />
      <img
        className="tape-down1"
        ref={solidTapeDownRef1}
        src={Tape}
        alt="tape"
      />
      <img className="tape-up2" ref={solidTapeUpRef2} src={Tape} alt="tape" />
      <img className="photo2" ref={solidLogoRef2} src={photo2} alt="photo2" />
      <img
        className="tape-down2"
        ref={solidTapeDownRef2}
        src={Tape}
        alt="tape"
      />
      <img className="tape-up3" ref={solidTapeUpRef3} src={Tape} alt="tape" />
      <img className="photo3" ref={solidLogoRef3} src={photo3} alt="photo3" />
      <img
        className="tape-down3"
        ref={solidTapeDownRef3}
        src={Tape}
        alt="tape"
      />
    </div>
  )
}

export default Logo
