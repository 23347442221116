import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyC0bEIFpqtkMFezurgsts2SLLKLlyCZw5s',
  authDomain: 'portfolio-website-9b28f.firebaseapp.com',
  projectId: 'portfolio-website-9b28f',
  storageBucket: 'portfolio-website-9b28f.appspot.com',
  messagingSenderId: '116426732019',
  appId: '1:116426732019:web:606a3512b74a8cfecc6286',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth()
const provider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)

export const signInWithGoogle = () => signInWithPopup(auth, provider)
