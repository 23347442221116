import { Route, Routes, useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Splash from './components/Splash'
import Home from './components/Home'
import About from './components/About'
import Experience from './components/Experience'
import Contact from './components/Contact'
import Layout from './components/Layout'
import Portfolio from './components/Portfolio'
import Dashboard from './components/Dashboard'
import References from './components/References'
import './App.scss'

const App = () => {
  const [showSplash, setShowSplash] = useState(true)

  useEffect(() => {
    // Hide the splash screen after 3 seconds
    const timer = setTimeout(() => {
      setShowSplash(false)
    }, 6000)

    return () => clearTimeout(timer)
  }, [])

  const location = useLocation()

  if (showSplash) {
    return <Splash />
  }

  return (
    <>
      <Routes location={location}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="experience" element={<Experience />} />
          <Route path="contact" element={<Contact />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="references" element={<References />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
